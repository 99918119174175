import { kinds } from "nostr-tools";
import { getEventUID, isReplaceable } from "../helpers/event.js";
/** Creates a query that returns all reactions to an event (supports replaceable events) */
export function ReactionsQuery(event) {
    return {
        key: getEventUID(event),
        run: (events) => events.timeline(isReplaceable(event.kind)
            ? [
                { kinds: [kinds.Reaction], "#e": [event.id] },
                { kinds: [kinds.Reaction], "#a": [getEventUID(event)] },
            ]
            : [
                {
                    kinds: [kinds.Reaction],
                    "#e": [event.id],
                },
            ]),
    };
}
