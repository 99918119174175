import { useMemo } from "react";
import { truncateContent } from "applesauce-content/nast";
import { getParsedContent } from "applesauce-content/text";
import { useRenderNast } from "./use-render-nast.js";
import { buildLinkRenderer } from "../helpers/build-link-renderer.js";
/** Returns the parsed and render text content for an event */
export function useRenderedContent(event, components, opts) {
    // if link renderers are set, override the link components
    const _components = useMemo(() => (opts?.linkRenderers ? { ...components, link: buildLinkRenderer(opts.linkRenderers) } : components), [opts?.linkRenderers, components]);
    // add additional transformers
    const nast = useMemo(() => (event ? getParsedContent(event, opts?.content, opts?.transformers, opts?.cacheKey) : undefined), [event, opts?.content, opts?.transformers, opts?.cacheKey]);
    let truncated = nast;
    if (opts?.maxLength && nast)
        truncated = truncateContent(nast, opts.maxLength);
    return useRenderNast(truncated, _components);
}
