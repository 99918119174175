import { unified } from "unified";
import { getOrComputeCachedValue } from "applesauce-core/helpers/cache";
import { nostrMentions } from "./mentions.js";
import { cashuTokens } from "./cashu.js";
import { emojis } from "./emoji.js";
import { createTextNoteATS } from "./parser.js";
import { hashtags } from "./hashtag.js";
import { galleries } from "./gallery.js";
import { lightningInvoices } from "./lightning.js";
import { eolMetadata } from "../nast/eol-metadata.js";
import { links } from "./links.js";
export const TextNoteContentSymbol = Symbol.for("text-note-content");
// default kind 1 transformers
export const textNoteTransformers = [
    links,
    nostrMentions,
    galleries,
    emojis,
    hashtags,
    lightningInvoices,
    cashuTokens,
    eolMetadata,
];
/** Parsed and process a note with custom transformers */
export function getParsedContent(event, content, transformers = textNoteTransformers, cacheKey = TextNoteContentSymbol) {
    // process strings
    if (typeof event === "string") {
        const processor = unified();
        for (const transformer of transformers) {
            processor.use(transformer);
        }
        return processor.runSync(createTextNoteATS(event, content));
    }
    // no caching
    if (!cacheKey) {
        const processor = unified();
        for (const transformer of transformers) {
            processor.use(transformer);
        }
        return processor.runSync(createTextNoteATS(event, content));
    }
    return getOrComputeCachedValue(event, cacheKey, () => {
        const processor = unified();
        for (const transformer of transformers) {
            processor.use(transformer);
        }
        return processor.runSync(createTextNoteATS(event, content));
    });
}
export function removeParsedTextContent(event) {
    // @ts-expect-error
    delete event[TextNoteContentSymbol];
}
