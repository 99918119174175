import { parseBolt11 } from "applesauce-core/helpers/bolt11";
import Expressions from "../helpers/regexp.js";
import { findAndReplace } from "../nast/find-and-replace.js";
export function lightningInvoices() {
    return (tree) => {
        findAndReplace(tree, [
            [
                Expressions.lightning,
                (_, $1) => {
                    try {
                        const invoice = $1;
                        const parsed = parseBolt11(invoice);
                        return {
                            type: "lightning",
                            invoice,
                            parsed,
                        };
                    }
                    catch (error) { }
                    return false;
                },
            ],
        ]);
    };
}
