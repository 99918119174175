import { share } from "rxjs";
import { tap } from "rxjs/operators";
/**
 * Creates an operator that adds a 'value' property and multiplexes the source
 * @param config Optional ShareConfig for customizing sharing behavior
 */
export function shareLatestValue(config = {}) {
    return (source) => {
        // Create storage for latest value
        let latestValue = undefined;
        // Create shared source with value tracking
        const shared$ = source.pipe(tap((value) => {
            latestValue = value;
        }), share(config));
        // Add value property
        Object.defineProperty(shared$, "value", {
            get: () => latestValue,
        });
        return shared$;
    };
}
