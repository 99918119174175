export function getCachedValue(event, symbol) {
    return Reflect.get(event, symbol);
}
export function setCachedValue(event, symbol, value) {
    Reflect.set(event, symbol, value);
}
/** Internal method used to cache computed values on events */
export function getOrComputeCachedValue(event, symbol, compute) {
    if (Reflect.has(event, symbol)) {
        return Reflect.get(event, symbol);
    }
    else {
        const value = compute(event);
        Reflect.set(event, symbol, value);
        return value;
    }
}
