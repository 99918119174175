export function isETag(tag) {
    return tag[0] === "e" && tag[1] !== undefined;
}
export function isPTag(tag) {
    return tag[0] === "p" && tag[1] !== undefined;
}
export function isRTag(tag) {
    return tag[0] === "r" && tag[1] !== undefined;
}
export function isDTag(tag) {
    return tag[0] === "d" && tag[1] !== undefined;
}
export function isATag(tag) {
    return tag[0] === "a" && tag[1] !== undefined;
}
export function isTTag(tag) {
    return tag[0] === "a" && tag[1] !== undefined;
}
