/** Creates a {@link Root} ATS node for a text note */
export function createTextNoteATS(event, content) {
    return {
        type: "root",
        event: typeof event !== "string" ? event : undefined,
        children: [
            {
                type: "text",
                value: content || (typeof event === "string" ? event : event.content),
            },
        ],
    };
}
