import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import { memo, useMemo } from "react";
export function buildLinkRenderer(handlers) {
    const LinkRenderer = ({ node }) => {
        const content = useMemo(() => {
            try {
                const url = new URL(node.href);
                for (const handler of handlers) {
                    try {
                        const content = handler(url, node);
                        if (content)
                            return content;
                    }
                    catch (e) { }
                }
            }
            catch (error) { }
            return null;
        }, [node.href, node.value]);
        return content || _jsx(_Fragment, { children: node.value });
    };
    return memo(LinkRenderer);
}
