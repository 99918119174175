import { getHashtagTag } from "applesauce-core/helpers/hashtag";
import Expressions from "../helpers/regexp.js";
import { findAndReplace } from "../nast/find-and-replace.js";
export function hashtags() {
    return (tree) => {
        const event = tree.event;
        if (!event)
            return;
        findAndReplace(tree, [
            [
                Expressions.hashtag,
                (_, $1) => {
                    try {
                        const tag = getHashtagTag(event, $1);
                        if (!tag)
                            return false;
                        return {
                            type: "hashtag",
                            tag,
                            name: $1,
                            hashtag: tag[1].toLowerCase(),
                        };
                    }
                    catch (error) { }
                    return false;
                },
            ],
        ]);
    };
}
