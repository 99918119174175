import Expressions from "../helpers/regexp.js";
import { findAndReplace } from "../nast/find-and-replace.js";
export function links() {
    return (tree) => {
        findAndReplace(tree, [
            [
                Expressions.link,
                (_) => {
                    try {
                        return {
                            type: "link",
                            href: new URL(_).toString(),
                            value: _,
                        };
                    }
                    catch (error) { }
                    return false;
                },
            ],
        ]);
    };
}
