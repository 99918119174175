import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/** Render a nostr syntax tree to JSX components */
export function renderNast(root, components) {
    const indexes = {};
    return (_jsx(_Fragment, { children: root.children.map((node) => {
            indexes[node.type] = indexes[node.type] ?? 0;
            const index = indexes[node.type];
            indexes[node.type]++;
            const Component = components[node.type];
            if (!Component)
                return null;
            // @ts-expect-error
            return _jsx(Component, { node: node }, node.type + "-" + index);
        }) }));
}
