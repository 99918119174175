import { getDecodedToken } from "@cashu/cashu-ts";
import Expressions from "../helpers/regexp.js";
import { findAndReplace } from "../nast/find-and-replace.js";
/** Parse cashu tokens from an ATS tree */
export function cashuTokens() {
    return (tree) => {
        findAndReplace(tree, [
            [
                Expressions.cashu,
                (_, $1) => {
                    try {
                        const token = getDecodedToken($1);
                        return {
                            type: "cashu",
                            token,
                            raw: $1,
                        };
                    }
                    catch (error) { }
                    return false;
                },
            ],
        ]);
    };
}
