import { naddrEncode, neventEncode, noteEncode, nprofileEncode, npubEncode, nsecEncode, } from "nostr-tools/nip19";
import { getPublicKey } from "nostr-tools";
import { safeRelayUrls } from "./relays.js";
export function parseCoordinate(a, requireD = false, silent = true) {
    const parts = a.split(":");
    const kind = parts[0] && parseInt(parts[0]);
    const pubkey = parts[1];
    const d = parts[2];
    if (!kind) {
        if (silent)
            return null;
        else
            throw new Error("Missing kind");
    }
    if (!pubkey) {
        if (silent)
            return null;
        else
            throw new Error("Missing pubkey");
    }
    if (requireD && d === undefined) {
        if (silent)
            return null;
        else
            throw new Error("Missing identifier");
    }
    return {
        kind,
        pubkey,
        identifier: d,
    };
}
export function getPubkeyFromDecodeResult(result) {
    if (!result)
        return;
    switch (result.type) {
        case "naddr":
        case "nprofile":
            return result.data.pubkey;
        case "npub":
            return result.data;
        case "nsec":
            return getPublicKey(result.data);
        default:
            return undefined;
    }
}
export function encodeDecodeResult(result) {
    switch (result.type) {
        case "naddr":
            return naddrEncode(result.data);
        case "nprofile":
            return nprofileEncode(result.data);
        case "nevent":
            return neventEncode(result.data);
        case "nsec":
            return nsecEncode(result.data);
        case "npub":
            return npubEncode(result.data);
        case "note":
            return noteEncode(result.data);
    }
    return "";
}
export function getEventPointerFromTag(tag) {
    if (!tag[1])
        throw new Error("Missing event id in tag");
    let pointer = { id: tag[1] };
    if (tag[2])
        pointer.relays = safeRelayUrls([tag[2]]);
    return pointer;
}
export function getAddressPointerFromTag(tag) {
    if (!tag[1])
        throw new Error("Missing coordinate in tag");
    const pointer = parseCoordinate(tag[1], true, false);
    if (tag[2])
        pointer.relays = safeRelayUrls([tag[2]]);
    return pointer;
}
export function getProfilePointerFromTag(tag) {
    if (!tag[1])
        throw new Error("Missing pubkey in tag");
    const pointer = { pubkey: tag[1] };
    if (tag[2])
        pointer.relays = safeRelayUrls([tag[2]]);
    return pointer;
}
export function getPointerFromTag(tag) {
    try {
        switch (tag[0]) {
            case "e":
                return { type: "nevent", data: getEventPointerFromTag(tag) };
            case "a":
                return {
                    type: "naddr",
                    data: getAddressPointerFromTag(tag),
                };
            case "p":
                return { type: "nprofile", data: getProfilePointerFromTag(tag) };
        }
    }
    catch (error) { }
    return null;
}
export function isAddressPointer(pointer) {
    return (typeof pointer !== "string" &&
        Object.hasOwn(pointer, "identifier") &&
        Object.hasOwn(pointer, "pubkey") &&
        Object.hasOwn(pointer, "kind"));
}
export function isEventPointer(pointer) {
    return typeof pointer !== "string" && Object.hasOwn(pointer, "id");
}
export function getCoordinateFromAddressPointer(pointer) {
    return `${pointer.kind}:${pointer.pubkey}:${pointer.identifier}`;
}
export function getATagFromAddressPointer(pointer) {
    const relay = pointer.relays?.[0];
    const coordinate = getCoordinateFromAddressPointer(pointer);
    return relay ? ["a", coordinate, relay] : ["a", coordinate];
}
export function getETagFromEventPointer(pointer) {
    return pointer.relays?.length ? ["e", pointer.id, pointer.relays[0]] : ["e", pointer.id];
}
