import { kinds } from "nostr-tools";
import { map } from "rxjs/operators";
import { getInboxes, getOutboxes } from "../helpers/mailboxes.js";
export function MailboxesQuery(pubkey) {
    return {
        key: pubkey,
        run: (events) => events.replaceable(kinds.RelayList, pubkey).pipe(map((event) => event && {
            inboxes: getInboxes(event),
            outboxes: getOutboxes(event),
        })),
    };
}
