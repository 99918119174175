export const SeenRelaysSymbol = Symbol.for("seen-relays");
// Seen relays
export function addSeenRelay(event, relay) {
    if (!event[SeenRelaysSymbol])
        event[SeenRelaysSymbol] = new Set();
    event[SeenRelaysSymbol].add(relay);
    return event[SeenRelaysSymbol];
}
export function getSeenRelays(event) {
    return event[SeenRelaysSymbol];
}
// Relay URLs
export function validateRelayURL(relay) {
    if (typeof relay === "string" && relay.includes(",ws"))
        throw new Error("Can not have multiple relays in one string");
    const url = typeof relay === "string" ? new URL(relay) : relay;
    if (url.protocol !== "wss:" && url.protocol !== "ws:")
        throw new Error("Incorrect protocol");
    return url;
}
export function safeRelayUrl(relayUrl) {
    try {
        return validateRelayURL(relayUrl).toString();
    }
    catch (e) {
        return null;
    }
}
export function safeRelayUrls(urls) {
    return Array.from(urls).map(safeRelayUrl).filter(Boolean);
}
