import { decode } from "nostr-tools/nip19";
import Expressions from "../helpers/regexp.js";
import { findAndReplace } from "../nast/find-and-replace.js";
export function nostrMentions() {
    return (tree) => {
        findAndReplace(tree, [
            [
                Expressions.nostrLink,
                (_, $1) => {
                    try {
                        return {
                            type: "mention",
                            decoded: decode($1),
                            encoded: $1,
                        };
                    }
                    catch (error) { }
                    return false;
                },
            ],
        ]);
    };
}
